import { IBoardColor } from "@vestaboard/installables";

export const AUTH0_CLIENT_ID = process.env["REACT_APP_AUTH0_CLIENT_ID"];
export const AUTH0_DOMAIN = process.env["REACT_APP_AUTH0_DOMAIN"];
export const GRAPHQL_API_URL = `${process.env["REACT_APP_API_BASE_URL"]}/graphql`;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const PLATFORM_BASE_URL = process.env.REACT_APP_PLATFORM_BASE_URL;
export const BREAKPOINT_MEDIUM = "1200px";
export const BREAKPOINT_MEDIUM_MIN = "960px";
export const BREAKPOINT_SMALL = "959px";
export const BREAKPOINT_SMALL_MIN = "751px";
export const BREAKPOINT_EXTRA_SMALL = "750px";
export const BREAKPOINT_EXTRA_EXTRA_SMALL = "540px";
export const HOURS = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

export const MINUTES = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
];

export const BORDER_COLORS: Array<IBoardColor> = [
  "parisBlue",
  "violet",
  "orange",
  "yellow",
  "poppyRed",
  "green",
  "white",
];

export const ROLE_TYPES = ["User", "Admin", "Owner"];

// @TODO: Update this once we switch the DNS to share.vestaboard.com
export const SHARE_API_URL =
  //s"https://smart-data-88ph3.ampt.app" ||
  "https://precious-source-bfvwp.ampt.app";
