import * as React from "react";

import { ControlledWYSIWYGEditor } from "./WysiwygEditor";
import W3CWebSocket from "reconnecting-websocket";
import uuid from "uuid";

enum EDeviceMessageType {
  DisplayCommand = "DeviceMessageDisplayCommand",
  Heartbeat = "DeviceHeartbeatMessage",
  BoardLayoutChanged = "DeviceBoardLayoutChangedMessage",
  Refresh = "DeviceRefreshCommand",
  Identify = "DeviceIdentifyMessage",
}

interface ISimulatorFallbackProps {
  boardId: string;
}

type SimulatorFallbackProps = ISimulatorFallbackProps;

const SimulatorFallback: React.FC<SimulatorFallbackProps> = ({ boardId }) => {
  const [messageCharacterCodes, setMessageCharacterCodes] = React.useState({});

  React.useEffect(() => {
    const deviceId = uuid();
    const client = new W3CWebSocket(
      `wss://platform.vestaboard.com/ws/${deviceId}`
    );

    const constructDeviceMessage = (
      deviceId: string,
      type: EDeviceMessageType,
      payload: {}
    ) => {
      return JSON.stringify({
        message: {
          type,
          deviceId,
          clientTime: new Date().getTime(),
          ...payload,
        },
      });
    };

    const constructHeartbeatMessage = (deviceId: string) => {
      return constructDeviceMessage(deviceId, EDeviceMessageType.Heartbeat, {});
    };

    const constructIdentifyMessage = (deviceId: string, boardId: string) => {
      return constructDeviceMessage(deviceId, EDeviceMessageType.Identify, {
        boardId,
      });
    };

    const handleMessage = (message: string) => {
      if (message === "ok") {
        return;
      }

      const json = JSON.parse(message);

      switch (json.message.type) {
        case EDeviceMessageType.DisplayCommand:
          setMessageCharacterCodes(
            (json.message.characters.characters as Array<Array<number>>).reduce(
              (rowsAcc, rowColumns, rowIndex) => {
                return {
                  ...rowsAcc,
                  ...rowColumns.reduce((columnAcc, currValue, columnIndex) => {
                    return {
                      ...columnAcc,
                      [columnIndex + rowColumns.length * rowIndex]: currValue,
                    };
                  }, {}),
                };
              },
              {}
            )
          );
          break;
        default:
          break;
      }
    };

    const sendHeartbeat = () => {
      const message = constructHeartbeatMessage(deviceId);
      client.send(message);
    };

    const sendIdentify = () => {
      const message = constructIdentifyMessage(deviceId, boardId);
      client.send(message);
    };

    const scheduleHeartbeat = () => {
      setTimeout(() => {
        sendHeartbeat();
        scheduleHeartbeat();
      }, 5000);
    };

    const onConnect = () => {
      sendHeartbeat();
      sendIdentify();
      scheduleHeartbeat();
    };

    client.onopen = () => {
      onConnect();
    };

    client.onmessage = (message) => {
      handleMessage(message.data);
    };

    return () => {
      client.close();
    };
  }, [boardId]);

  return (
    <div style={{ pointerEvents: "none" }}>
      <ControlledWYSIWYGEditor characterCodes={messageCharacterCodes} />
    </div>
  );
};

interface ISimulatorProps {
  width: number;
  boardId?: string;
  message?: number[][];
  empty?: boolean;
  hoverable?: boolean;
  disableAnimation?: boolean;
  disableFrame?: boolean;
}

type SimulatorProps = ISimulatorProps;

export const SIMULATOR_BASE_URL = process.env.REACT_APP_SIMULATOR_BASE_URL;
export const getBoardSimulatorUrl = (
  boardId: string,
  disableAnimation: boolean = false
) =>
  `${SIMULATOR_BASE_URL}?${
    disableAnimation ? `disableAnimation=1&` : ""
  }boardId=${boardId}`;

export const Simulator: React.FC<SimulatorProps> = (props) => {
  if (!props.message && !props.boardId) {
    throw Error("unexpected - need either board id or message for simulator");
  }

  const aspectRatio = 0.535;

  const { width } = props;

  const containerDivStyle: React.CSSProperties = {
    position: "relative",
    width,
    height: width * aspectRatio,
    overflow: "hidden",
  };

  const innerDivStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
  };

  const disableAnimationBit = props.disableAnimation ? 1 : 0;
  const frameBit = props.disableFrame ? 0 : 1;

  const simulatorUrl = props.boardId
    ? `${SIMULATOR_BASE_URL}?disableAnimation=${disableAnimationBit}&boardId=${props.boardId}&frame=${frameBit}`
    : props.message
    ? `${SIMULATOR_BASE_URL}?disableAnimation=${disableAnimationBit}&message=${btoa(
        JSON.stringify(props.message)
      )}&frame=${frameBit}`
    : SIMULATOR_BASE_URL;

  const iFrameProps = {
    ...innerDivStyle,
    width: width,
    height: width * 0.57,
    src: simulatorUrl,
    style: { border: 0 },
    scrolling: "no",
  };

  const overlayDivStyle = (hover: boolean): React.CSSProperties => ({
    background: undefined,
    position: "absolute",
    top: 0,
    left: 0,
    transition: "background 0.25s linear",
    ...innerDivStyle,
  });

  const [hovered, setHovered] = React.useState(false);

  const useMobileFallback =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

  return (
    <div className="shadow-5" style={containerDivStyle}>
      <div
        onMouseEnter={() => setHovered(true && !!props.hoverable)}
        onMouseLeave={() => setHovered(false)}
        style={overlayDivStyle(hovered)}
      ></div>
      {!(useMobileFallback && props.boardId) ? (
        <iframe title="simulator" {...iFrameProps} translate="yes" />
      ) : (
        <SimulatorFallback boardId={props.boardId} />
      )}
    </div>
  );
};
