import {
  BREAKPOINT_SMALL,
  BREAKPOINT_EXTRA_EXTRA_SMALL,
  ROLE_TYPES,
} from "../../../config";
import { BoardMembersQuery, BoardMembersQueryVariables } from "../../../gql";
import {
  Box,
  Button,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  ButtonBase,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import React, { useState } from "react";
import { useHistory, useParams } from "react-router";

import { ActionButton } from "../../../components/ActionButton";
import { AddUser } from "./AddUser";
import { BorderContainer } from "../../../components/BorderContainer";
import CheckIcon from "@mui/icons-material/Check";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { RevokeInvitation } from "./RevokeInvitation";
import { Tab } from "../../../components/Tab";
import { Tabs } from "../../../components/Tabs";
import { UserRole } from "./UserRole";
import gql from "graphql-tag";
import { useFeatures } from "../../../hooks/useFeatures";
import { useQuery } from "@apollo/client";
import { TransferOwnershipModal } from "./TransferOwnershipModal";
import { useGetRole } from "../../../hooks/useGetRole";

interface IRouteParams {
  boardId: string;
}

export const BOARD_MEMBERS_QUERY = gql`
  query BoardMembersQuery($boardId: String!) {
    viewer {
      id
      account {
        id
      }
    }
    board(id: $boardId) {
      id
      title
      tenant {
        id
        members {
          id
          role
          isCurrentMember
          invitationStatus
          person {
            id
            firstName
            lastName
            account {
              id
              emailAddress
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles({
  ownerBorderContainer: {
    marginBottom: -2,
  },
  transferOwnershipContainer: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    borderColor: "#585f64",
    borderWidth: 2,
  },
  titleAndRoleContainer: {
    display: "flex",
    alignItems: "center",
    [`@media(max-width: ${BREAKPOINT_EXTRA_EXTRA_SMALL})`]: {
      justifyContent: "space-between",
    },
  },
  container: {
    maxWidth: 736,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      maxWidth: "100%",
    },
  },
  titleBox: {
    minWidth: 300,
    marginRight: 8,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      minWidth: 200,
    },
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    fontFamily: "HelveticaNeue",
  },
  plus: {
    width: 18,
    height: 15,
  },
  roleDescription: {
    padding: "2em 4em",
  },
  listHeader: {
    fontWeight: "bold",
  },
  addUserItemContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const roleFeatures = {
  User: [
    "Create messages",
    "Schedule messages",
    "Edit messages",
    "Favorite messages",
  ],
  Admin: [
    "Set quiet hours",
    "Set timezone",
    "Add or remove users",
    "Manage network connection",
  ],
  Owner: ["Delete board", "Manage billing", "Transfer ownership"],
};

export const Users = () => {
  const [activeRoleTab, setActiveRoleTab] = useState<number>(0);
  const [removeUserId, setRemoveUserId] = useState<null | string>(null);
  const [revokeUserId, setRevokeUserId] = useState<null | string>(null);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [showTransferModal, setShowTransferModal] = useState<boolean>(false);

  const { features } = useFeatures();
  const history = useHistory();
  const params = useParams<IRouteParams>();

  const { iAmOwner, iAmAdmin } = useGetRole(params.boardId);

  const { loading, data, refetch } = useQuery<
    BoardMembersQuery,
    BoardMembersQueryVariables
  >(BOARD_MEMBERS_QUERY, {
    variables: {
      boardId: params.boardId,
    },
  });
  const classes = useStyles();
  const [isAddingUser, setIsAddingUser] = React.useState(false);

  if (loading || !data) {
    return <CircularProgress />;
  }

  const boardMembers = data.board.tenant.members;
  const sortedBoardMembers = [
    ...boardMembers.filter(({ role }) => role === "Owner"),
    ...boardMembers.filter(({ role }) => role !== "Owner"),
  ];

  const myId = data.viewer && data.viewer.account && data.viewer.account.id;

  return (
    <>
      <Dialog
        open={showInfoModal}
        onClose={() => setShowInfoModal(false)}
        maxWidth="lg"
      >
        <DialogContent>
          <Tabs onChange={setActiveRoleTab} value={activeRoleTab || 0}>
            {ROLE_TYPES.map((role) => (
              <Tab label={role} key={role} />
            ))}
          </Tabs>
          <Box className={classes.roleDescription}>
            {activeRoleTab !== 0 && (
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.listHeader}
                    >
                      User Features:
                    </Typography>
                  }
                />
              </ListItem>
            )}
            {roleFeatures.User.map((feature) => (
              <ListItem key={feature}>
                <ListItemIcon>
                  <CheckIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={feature} />
              </ListItem>
            ))}
            {activeRoleTab !== 0 && (
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.listHeader}
                    >
                      Plus:
                    </Typography>
                  }
                />
              </ListItem>
            )}
            {activeRoleTab !== 0 &&
              [
                ...roleFeatures.Admin,
                ...(activeRoleTab === 2 ? roleFeatures.Owner : []),
              ].map((feature) => (
                <ListItem key={feature}>
                  <ListItemIcon>
                    <CheckIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={feature} />
                </ListItem>
              ))}
          </Box>
        </DialogContent>
      </Dialog>
      <RevokeInvitation
        title="Revoke Invitation"
        message="Are you sure you want to revoke this invitation?"
        personTenantAssociationId={revokeUserId}
        onCancel={() => {
          setRevokeUserId(null);
        }}
        onComplete={refetch}
      />
      <RevokeInvitation
        title="Remove User"
        message="Are you sure you want to remove this user?"
        personTenantAssociationId={removeUserId}
        onCancel={() => {
          setRemoveUserId(null);
        }}
        onComplete={refetch}
      />
      <Box className={classes.container}>
        {sortedBoardMembers
          .filter((member) => member.invitationStatus !== "Revoked")
          .map((member, index) => {
            const isMe = myId === member.person.account.id;
            const isOwner = member.role === "Owner";

            return (
              <Box key={member.id}>
                <Box style={{ zIndex: index }}>
                  <BorderContainer
                    responsive
                    className={
                      iAmOwner && isMe ? classes.ownerBorderContainer : ""
                    }
                  >
                    <Box className={classes.titleAndRoleContainer}>
                      <Box className={classes.titleBox}>
                        <Typography className={classes.title}>
                          {member.person.firstName
                            ? `${member.person.firstName || ""} ${
                                member.person.lastName || ""
                              }`
                            : member.person.account.emailAddress}
                        </Typography>
                      </Box>
                      <UserRole
                        role={member.role}
                        disabled={isMe || !iAmAdmin}
                        personTenantAssociationId={member.id}
                      />
                    </Box>
                    {isMe && features.userProfile ? (
                      <ActionButton
                        onClick={() => {
                          history.push(`/board/${params.boardId}/profile`);
                        }}
                      >
                        Edit Profile
                      </ActionButton>
                    ) : !member.person.firstName ? (
                      features.settings.modifyUsers ? (
                        <ActionButton
                          onClick={() => {
                            setRevokeUserId(member.id);
                          }}
                          buttonColor="danger"
                          disabled={!iAmAdmin}
                        >
                          Revoke Invite
                        </ActionButton>
                      ) : (
                        <Box />
                      )
                    ) : features.settings.modifyUsers && !isOwner ? (
                      <ActionButton
                        onClick={() => {
                          setRemoveUserId(member.id);
                        }}
                        disabled={!iAmAdmin}
                      >
                        Remove
                      </ActionButton>
                    ) : (
                      <Box />
                    )}
                  </BorderContainer>
                </Box>
                {iAmOwner && isMe ? (
                  <Box style={{ zIndex: index - 1 }}>
                    <BorderContainer
                      responsive
                      className={classes.transferOwnershipContainer}
                    >
                      <ButtonBase
                        onClick={() => {
                          setShowTransferModal(true);
                        }}
                        style={{
                          width: "100%",
                        }}
                      >
                        Transfer Ownership
                      </ButtonBase>
                    </BorderContainer>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            );
          })}
        {iAmAdmin &&
          features.settings.modifyUsers &&
          (isAddingUser ? (
            <AddUser
              handleClose={() => setIsAddingUser(false)}
              boardId={params.boardId}
              refetchUsers={refetch}
              showInfoModal={showInfoModal}
              openInfoModal={() => setShowInfoModal(true)}
              iAmAdmin={iAmAdmin || false}
            />
          ) : (
            <BorderContainer>
              <Box className={classes.addUserItemContainer}>
                <Typography className={classes.title}>Add User</Typography>
                <Button
                  onClick={() => {
                    setIsAddingUser(true);
                  }}
                >
                  <img
                    alt={"Add User"}
                    src={`/icons/icon-plus.svg`}
                    className={classes.plus}
                  />
                </Button>
              </Box>
            </BorderContainer>
          ))}
      </Box>
      <TransferOwnershipModal
        visible={showTransferModal}
        setVisible={setShowTransferModal}
      />
    </>
  );
};
