import {
  SetMessageFavoriteMutation,
  SetMessageFavoriteMutationVariables,
  MessageFavoritesQuery,
} from "../gql";
import { useMutation, useQuery } from "@apollo/client";

// import { Favorites } from "../providers/FavoritesProvider";
import { Optional } from "../utils/optional";

// import React from "react";
import gql from "graphql-tag";

const messageFavoritesQuery = gql`
  query MessageFavoritesQuery($page: Int!) {
    viewer {
      id
      account {
        id
        person {
          id
          favoritesCount
          favorites(page: $page, perPage: 30) {
            id
            message {
              id
              text
              authorFormatted
              formatted
              created
              sendAt
            }
          }
        }
      }
    }
  }
`;

const setFavoriteMutation = gql`
  mutation SetMessageFavoriteMutation(
    $messageId: String!
    $favorited: Boolean!
  ) {
    setMessageFavorite(input: { message: $messageId, favorited: $favorited }) {
      messageFavorite {
        id
        favorited
        message {
          id
        }
      }
    }
  }
`;

export type UsePersonFavoritesListResponse = {
  data: Optional<MessageFavoritesQuery>;
  favorites: string[];
  addFavoritedMessage: (id: string) => Promise<void>;
  removeFavoritedMessage: (id: string) => Promise<void>;
  loading: boolean;
  mutating: boolean;
  refetch: () => void;
  count: number;
};

export const usePersonFavoritesList = (
  page: number = 0
): UsePersonFavoritesListResponse => {
  const { data, loading, refetch } = useQuery<MessageFavoritesQuery>(
    messageFavoritesQuery,
    {
      variables: {
        page,
      },
      // fetchPolicy: "network-only",
    }
  );
  const [mutation, { loading: mutating }] = useMutation<
    SetMessageFavoriteMutation,
    SetMessageFavoriteMutationVariables
  >(setFavoriteMutation);

  const addFavoritedMessage = async (id: string) => {
    await mutation({
      variables: {
        messageId: id,
        favorited: true,
      },
      refetchQueries: [
        {
          query: messageFavoritesQuery,
          variables: {
            page: 0,
          },
        },
      ],
    });
  };

  const removeFavoritedMessage = async (id: string) => {
    await mutation({
      variables: {
        messageId: id,
        favorited: false,
      },
      refetchQueries: [
        {
          query: messageFavoritesQuery,
          variables: {
            page: 0,
          },
        },
        {
          query: messageFavoritesQuery,
          variables: {
            page,
          },
        },
      ],
    });
  };

  return {
    data,
    favorites:
      data?.viewer?.account?.person?.favorites.map(
        (favorite) => favorite.message.id
      ) || [],
    addFavoritedMessage,
    removeFavoritedMessage,
    loading,
    mutating,
    refetch,
    count: data?.viewer?.account?.person.favoritesCount ?? 0,
  };
};
