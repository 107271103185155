import * as React from "react";
import W3CWebSocket from "reconnecting-websocket";

export enum BoardOnlineOfflineStatus {
  Unknown = "Unknown",
  Online = "Online",
  Offline = "Offline",
}

const THRESHOLD = 3 * 60 * 1000;

export const useBoardOnlineOfflineStatus = (
  boardId: string
): BoardOnlineOfflineStatus | null => {
  const [latestTimestamp, setLatestTimestamp] = React.useState(
    null as number | null
  );
  const [currentTimestamp, setCurrentTimestamp] = React.useState(
    null as number | null
  );
  const timeoutRef = React.useRef<NodeJS.Timeout | null>();

  const deadManSwitch = () =>
    setTimeout(() => {
      setLatestTimestamp(-1);
      setCurrentTimestamp(Date.now());
    }, THRESHOLD);

  React.useEffect(() => {
    deadManSwitch();

    const client = new W3CWebSocket(
      `wss://platform.vestaboard.com/board-status/${boardId}`
    );

    client.onmessage = (message) => {
      const json = JSON.parse(message.data);

      if (latestTimestamp !== json.latestHeartbeat) {
        setLatestTimestamp(json.latestHeartbeat);
        setCurrentTimestamp(json.currentTime);
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = deadManSwitch();
        }
      }
    };

    return () => {
      client.close();
    };
  }, [boardId]);

  if (!latestTimestamp || !currentTimestamp) {
    return BoardOnlineOfflineStatus.Unknown;
  }

  if (currentTimestamp - THRESHOLD < latestTimestamp) {
    return BoardOnlineOfflineStatus.Online;
  } else {
    return BoardOnlineOfflineStatus.Offline;
  }
};
